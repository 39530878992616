import React, { useEffect, useState, useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import './App.css';
import { useSwipeable } from 'react-swipeable';

function App() {
  const [images, setImages] = useState([]);
  const [isAutoPlay, setIsAutoPlay] = useState(true); // Track autoplay state
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Track screen size for responsive layout
  const galleryRef = useRef(null); // Ref for gallery

  useEffect(() => {
    // Fetch images from the server
    fetch('https://dm7pk2abul.execute-api.us-east-1.amazonaws.com/prod/images', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ /* any payload data if required by your API */ })
    })
      .then(response => response.json())
      .then(data => {
        const urls = JSON.parse(data.body);
        const items = urls.map((url, index) => ({
          original: url,
          thumbnail: url,
          description: `Image ${index + 1}`,
        }));
        setImages(items);
      })
      .catch(error => console.error('Error loading images:', error));

    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleAutoPlay = () => {
    setIsAutoPlay(!isAutoPlay);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => galleryRef.current.slideNext(),
    onSwipedRight: () => galleryRef.current.slidePrev(),
  });

  return (
    <div className="App">
      <h1>Deer Pics</h1>
      <div {...handlers}>
        <ImageGallery
          ref={galleryRef}
          items={images}
          showThumbnails={!isMobile}
          showFullscreenButton={true}
          showPlayButton={false}
          autoPlay={!isAutoPlay}
          slideInterval={3000}
          additionalClass="custom-gallery"
        />
      </div>
    </div>
  );
}

export default App;
